import { NgModule } from '@angular/core';
import { SignaturePad } from './signature-pad';



export class SignaturePadModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [ ],
  declarations: [ SignaturePad ],
  exports: [ SignaturePad ],
}, ] },
];
/** @nocollapse */
static ctorParameters: ({type: any, decorators?: DecoratorInvocation[]}|null)[] = [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
